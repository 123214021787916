import { h } from "preact";

export function LinkIcon() {
  return (
    <div className="imo-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.8805 3.62827C12.1515 2.89927 11.3166 2.65842 10.5768 2.69736C9.87029 2.73454 9.23769 3.02844 8.87357 3.39256L6.98795 5.27818L8.63787 5.51388L9.81638 4.33537C9.92367 4.22809 10.2339 4.05058 10.6469 4.02885C11.0268 4.00885 11.4882 4.12156 11.9377 4.57107C12.3872 5.02059 12.4999 5.48201 12.4799 5.86186C12.4582 6.2749 12.2807 6.58511 12.1734 6.6924L9.81638 9.04942C9.70909 9.1567 9.39888 9.33421 8.98584 9.35594C8.60599 9.37594 8.14457 9.26323 7.69506 8.81371L6.98795 8.10661L6.28085 8.81371C6.28085 8.81371 6.24891 9.25318 6.75225 9.75652C7.48124 10.4855 8.31619 10.7264 9.05592 10.6874C9.76247 10.6502 10.3951 10.3563 10.7592 9.99223L13.1162 7.6352C13.4803 7.27109 13.7742 6.63849 13.8114 5.93194C13.8504 5.19221 13.6095 4.35726 12.8805 3.62827ZM3.45248 13.0564C4.18148 13.7854 5.01643 14.0262 5.75616 13.9873C6.46271 13.9501 7.0953 13.6562 7.45942 13.2921L9.34504 11.4064L7.69512 11.1707L6.51661 12.3493C6.40933 12.4565 6.09912 12.634 5.68608 12.6558C5.30622 12.6758 4.84481 12.5631 4.39529 12.1136C3.94578 11.664 3.83307 11.2026 3.85306 10.8228C3.8748 10.4097 4.0523 10.0995 4.15959 9.99223L6.51661 7.63521C6.6239 7.52792 6.93411 7.35042 7.34715 7.32868C7.727 7.30869 8.18842 7.42139 8.63793 7.87091L9.34504 8.57802L10.0521 7.87091C10.0521 7.87091 10.0841 7.43144 9.58074 6.9281C8.85175 6.19911 8.0168 5.95826 7.27707 5.99719C6.57052 6.03438 5.93792 6.32828 5.5738 6.6924L3.21678 9.04942C2.85266 9.41354 2.55876 10.0461 2.52157 10.7527C2.48264 11.4924 2.72349 12.3274 3.45248 13.0564Z"
        />
      </svg>
    </div>
  );
}
